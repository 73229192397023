*, ::before, ::after{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 rgba(0,0,0,0);
    --tw-ring-shadow: 0 0 rgba(0,0,0,0);
    --tw-shadow: 0 0 rgba(0,0,0,0);
    --tw-shadow-colored: 0 0 rgba(0,0,0,0);
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}
::backdrop{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 rgba(0,0,0,0);
    --tw-ring-shadow: 0 0 rgba(0,0,0,0);
    --tw-shadow: 0 0 rgba(0,0,0,0);
    --tw-shadow-colored: 0 0 rgba(0,0,0,0);
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}
.container{
    width: 100%;
}
@media (min-width: 640px){
    .container{
        max-width: 640px;
    }
}
@media (min-width: 768px){
    .container{
        max-width: 768px;
    }
}
@media (min-width: 1024px){
    .container{
        max-width: 1024px;
    }
}
@media (min-width: 1280px){
    .container{
        max-width: 1280px;
    }
}
@media (min-width: 1536px){
    .container{
        max-width: 1536px;
    }
}
.sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
.pointer-events-none{
    pointer-events: none;
}
.invisible{
    visibility: hidden;
}
.collapse{
    visibility: collapse;
}
.absolute{
    position: absolute;
}
.relative{
    position: relative;
}
.inset-x-0{
    left: 0px;
    right: 0px;
}
.bottom-0{
    bottom: 0px;
}
.bottom-\[20px\]{
    bottom: 20px;
}
.left-\[0px\]{
    left: 0px;
}
.right-\[0px\]{
    right: 0px;
}
.top-\[0px\]{
    top: 0px;
}
.z-0{
    z-index: 0;
}
.z-\[1\]{
    z-index: 1;
}
.z-\[9999\]{
    z-index: 9999;
}
.float-right{
    float: right;
}
.float-left{
    float: left;
}
.m-0{
    margin: 0px;
}
.m-1{
    margin: 0.25rem;
}
.m-2{
    margin: 0.5rem;
}
.m-4{
    margin: 1rem;
}
.-my-1\.5{
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
}
.mx-4{
    margin-left: 1rem;
    margin-right: 1rem;
}
.mx-auto{
    margin-left: auto;
    margin-right: auto;
}
.my-10{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}
.my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.my-3{
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}
.my-4{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.\!mb-0{
    margin-bottom: 0px !important;
}
.-mr-1\.5{
    margin-right: -0.375rem;
}
.mb-10{
    margin-bottom: 2.5rem;
}
.mb-2{
    margin-bottom: 0.5rem;
}
.mb-3{
    margin-bottom: 0.75rem;
}
.mb-4{
    margin-bottom: 1rem;
}
.mb-5{
    margin-bottom: 1.25rem;
}
.ml-0{
    margin-left: 0px;
}
.ml-2{
    margin-left: 0.5rem;
}
.ml-5{
    margin-left: 1.25rem;
}
.ml-\[1\.5rem\]{
    margin-left: 1.5rem;
}
.mr-1\.5{
    margin-right: 0.375rem;
}
.mr-2{
    margin-right: 0.5rem;
}
.mr-4{
    margin-right: 1rem;
}
.mr-\[6rem\]{
    margin-right: 6rem;
}
.mt-0{
    margin-top: 0px;
}
.mt-10{
    margin-top: 2.5rem;
}
.mt-14{
    margin-top: 3.5rem;
}
.mt-2{
    margin-top: 0.5rem;
}
.mt-3{
    margin-top: 0.75rem;
}
.mt-4{
    margin-top: 1rem;
}
.mt-5{
    margin-top: 1.25rem;
}
.mt-6{
    margin-top: 1.5rem;
}
.mt-8{
    margin-top: 2rem;
}
.mt-\[1rem\]{
    margin-top: 1rem;
}
.box-border{
    box-sizing: border-box;
}
.block{
    display: block;
}
.inline-block{
    display: inline-block;
}
.inline{
    display: inline;
}
.flex{
    display: flex;
}
.inline-flex{
    display: inline-flex;
}
.table{
    display: table;
}
.grid{
    display: grid;
}
.hidden{
    display: none;
}
.h-0\.5{
    height: 0.125rem;
}
.h-2{
    height: 0.5rem;
}
.h-32{
    height: 8rem;
}
.h-5{
    height: 1.25rem;
}
.h-6{
    height: 1.5rem;
}
.h-8{
    height: 2rem;
}
.h-\[4rem\]{
    height: 4rem;
}
.h-\[5rem\]{
    height: 5rem;
}
.h-screen{
    height: 100vh;
}
.max-h-96{
    max-height: 24rem;
}
.min-h-screen{
    min-height: 100vh;
}
.w-0{
    width: 0px;
}
.w-2{
    width: 0.5rem;
}
.w-4\/12{
    width: 33.333333%;
}
.w-5{
    width: 1.25rem;
}
.w-6{
    width: 1.5rem;
}
.w-8{
    width: 2rem;
}
.w-8\/12{
    width: 66.666667%;
}
.w-\[120px\]{
    width: 120px;
}
.w-\[200px\]{
    width: 200px;
}
.w-\[4rem\]{
    width: 4rem;
}
.w-auto{
    width: auto;
}
.w-full{
    width: 100%;
}
.min-w-0{
    min-width: 0px;
}
.min-w-full{
    min-width: 100%;
}
.max-w-0{
    max-width: 0px;
}
.max-w-2xl{
    max-width: 42rem;
}
.max-w-3xl{
    max-width: 48rem;
}
.max-w-6xl{
    max-width: 72rem;
}
.max-w-7xl{
    max-width: 80rem;
}
.max-w-\[900px\]{
    max-width: 900px;
}
.max-w-md{
    max-width: 28rem;
}
.flex-1{
    flex: 1 1 0%;
}
.flex-auto{
    flex: 1 1 auto;
}
.flex-none{
    flex: none;
}
.flex-shrink-0{
    flex-shrink: 0;
}
.flex-grow{
    flex-grow: 1;
}
.transform{
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cursor-pointer{
    cursor: pointer;
}
.resize{
    resize: both;
}
.list-none{
    list-style-type: none;
}
.grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr));
}
.flex-col{
    flex-direction: column;
}
.items-center{
    align-items: center;
}
.justify-start{
    justify-content: flex-start;
}
.justify-end{
    justify-content: flex-end;
}
.justify-center{
    justify-content: center;
}
.justify-between{
    justify-content: space-between;
}
.gap-5{
    gap: 1.25rem;
}
.space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * (1 - var(--tw-space-y-reverse)));
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.divide-x > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * (1 - var(--tw-divide-x-reverse)));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}
.divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
.divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
}
.overflow-hidden{
    overflow: hidden;
}
.overflow-y-scroll{
    overflow-y: scroll;
}
.truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.whitespace-nowrap{
    white-space: nowrap;
}
.rounded{
    border-radius: 0.25rem;
}
.rounded-full{
    border-radius: 9999px;
}
.rounded-lg{
    border-radius: 0.5rem;
}
.rounded-md{
    border-radius: 0.375rem;
}
.rounded-l-lg{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}
.rounded-r-lg{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
.border{
    border-width: 1px;
}
.border-2{
    border-width: 2px;
}
.border-4{
    border-width: 4px;
}
.border-b{
    border-bottom-width: 1px;
}
.border-t{
    border-top-width: 1px;
}
.border-t-4{
    border-top-width: 4px;
}
.border-solid{
    border-style: solid;
}
.border-dashed{
    border-style: dashed;
}
.border-blue-600{
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}
.border-gray-200{
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-gray-600{
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.border-green-500{
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}
.border-logoblue-100{
    --tw-border-opacity: 1;
    border-color: rgba(60, 51, 122, var(--tw-border-opacity));
}
.border-purple-600{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}
.border-red-500{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.border-red-600{
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.border-transparent{
    border-color: transparent;
}
.\!bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
}
.\!bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
}
.\!bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
}
.bg-blue-600{
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.bg-gray-100{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-gray-200{
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-gray-50{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.bg-green-600{
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.bg-green-700{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.bg-logoblue-100{
    --tw-bg-opacity: 1;
    background-color: rgba(60, 51, 122, var(--tw-bg-opacity));
}
.bg-purple-600{
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.bg-red-500{
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.bg-red-600{
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.bg-transparent{
    background-color: transparent;
}
.bg-white{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.p-1\.5{
    padding: 0.375rem;
}
.p-2{
    padding: 0.5rem;
}
.p-3{
    padding: 0.75rem;
}
.p-5{
    padding: 1.25rem;
}
.p-6{
    padding: 1.5rem;
}
.px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.px-4{
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.px-8{
    padding-left: 2rem;
    padding-right: 2rem;
}
.px-\[4rem\]{
    padding-left: 4rem;
    padding-right: 4rem;
}
.py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.py-4{
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-8{
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.py-\[0\.8rem\]{
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}
.pb-\[20px\]{
    padding-bottom: 20px;
}
.pl-2{
    padding-left: 0.5rem;
}
.pt-1{
    padding-top: 0.25rem;
}
.pt-4{
    padding-top: 1rem;
}
.pt-7{
    padding-top: 1.75rem;
}
.pt-\[10px\]{
    padding-top: 10px;
}
.pt-\[10rem\]{
    padding-top: 10rem;
}
.text-left{
    text-align: left;
}
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.text-2xl{
    font-size: 1.5rem;
    line-height: 2rem;
}
.text-3xl{
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.text-6xl{
    font-size: 3.75rem;
    line-height: 1;
}
.text-8xl{
    font-size: 6rem;
    line-height: 1;
}
.text-base{
    font-size: 1rem;
    line-height: 1.5rem;
}
.text-lg{
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.text-sm{
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-xl{
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.text-xs{
    font-size: 0.75rem;
    line-height: 1rem;
}
.font-bold{
    font-weight: 700;
}
.font-extrabold{
    font-weight: 800;
}
.font-medium{
    font-weight: 500;
}
.font-normal{
    font-weight: 400;
}
.font-semibold{
    font-weight: 600;
}
.uppercase{
    text-transform: uppercase;
}
.capitalize{
    text-transform: capitalize;
}
.ordinal{
    --tw-ordinal: ordinal;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}
.leading-6{
    line-height: 1.5rem;
}
.leading-7{
    line-height: 1.75rem;
}
.tracking-wider{
    letter-spacing: 0.05em;
}
.\!text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}
.text-black{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.text-blue-400{
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
}
.text-blue-600{
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.text-gray-400{
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.text-gray-500{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-900{
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.text-green-500{
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.text-indigo-600{
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
}
.text-logoblue-100{
    --tw-text-opacity: 1;
    color: rgba(60, 51, 122, var(--tw-text-opacity));
}
.text-purple-600{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
}
.text-red-500{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-red-600{
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
}
.text-white{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.no-underline{
    text-decoration-line: none;
}
.opacity-0{
    opacity: 0;
}
.shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 rgba(0,0,0,0);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0,0,0,0));
}
.ring-gray-800{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}
.ring-logoblue-100{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(60, 51, 122, var(--tw-ring-opacity));
}
.drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter{
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition{
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.duration-300{
    transition-duration: 300ms;
}
.ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@import '~purecss/build/pure.css';
@import '~purecss/build/grids-responsive.css';
@import 'css/font-icons.scss';
@import 'css/vars.scss';
@import 'css/shared/base-elements.scss';
@import 'css/shared/purecss-extensions.scss';
@import 'css/shared/micromodal.scss';
@import 'css/shared/header.scss';

.pure-menu-horizontal .pure-menu-active > .pure-menu-children,
.pure-menu-horizontal .pure-menu-allow-hover:hover > .pure-menu-children {
    width: 200px;
}
.pure-menu-horizontal .pure-menu-children {
    left: -32px;
}

.pure-menu-horizontal .pure-menu-has-children > .pure-menu-link::after {
    content: none;
}

#notice {
    color: $white-1;
    background: $purple-5;
    text-align: center;
    padding: 5px 0px;
}

#notice a,
#notice a:hover,
#notice a:active {
    color: $white-1;
    text-decoration: underline;
}

.pad-table th,
.pad-table td {
    padding-right: 1em;
    padding-bottom: 0.2em;
}

.hidden {
    display: none;
}

.pure-container {
    margin: 0 auto;
    max-width: 1200px;
}
.container {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
    max-width: 1920px;
}
.no-padding {
    padding: 0px;
}
.content-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
    padding-top: 30px;
    margin: 0 auto;
    max-width: 900px;
}
.larger-content-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
    padding-top: 30px;
    margin: 0 auto;
    max-width: 1270px;
}
#content {
    background-color: $black-1;
}
#footer {
    background: $black-1;
    padding-top: 20px;
    padding-bottom: 20px;
}

#map {
    width: 700px;
    height: 400px;
}

/** Additional pure styles that PureCSS doesn't provide because they
                are overly opinionated */
@media screen and (max-width: 35.499em) {
    .pure-visible-sm {
        display: none !important;
    }
    .pure-visible-md {
        display: none !important;
    }
    .pure-visible-lg {
        display: none !important;
    }
    .pure-visible-xl {
        display: none !important;
    }
    .pure-hidden-xs {
        display: none !important;
    }
}

@media screen and (min-width: 35.5em) and (max-width: 47.999em) {
    .pure-visible-md {
        display: none !important;
    }
    .pure-visible-lg {
        display: none !important;
    }
    .pure-visible-xl {
        display: none !important;
    }
    .pure-hidden-xs {
        display: none !important;
    }
    .pure-hidden-sm {
        display: none !important;
    }
}

@media screen and (min-width: 48em) and (max-width: 63.999em) {
    .pure-visible-lg {
        display: none !important;
    }
    .pure-visible-xl {
        display: none !important;
    }
    .pure-hidden-xs {
        display: none !important;
    }
    .pure-hidden-sm {
        display: none !important;
    }
    .pure-hidden-md {
        display: none !important;
    }
}

@media screen and (min-width: 64em) and (max-width: 79.999em) {
    .pure-visible-xl {
        display: none !important;
    }
    .pure-hidden-xs {
        display: none !important;
    }
    .pure-hidden-sm {
        display: none !important;
    }
    .pure-hidden-md {
        display: none !important;
    }
    .pure-hidden-lg {
        display: none !important;
    }
}
@media screen and (min-width: 80em) {
    .pure-hidden-xs {
        display: none !important;
    }
    .pure-hidden-sm {
        display: none !important;
    }
    .pure-hidden-md {
        display: none !important;
    }
    .pure-hidden-lg {
        display: none !important;
    }
    .pure-hidden-xl {
        display: none !important;
    }
}

/**************** ACCORDION ***************************************************/
.half {
    float: left;
    width: 50%;
    padding: 0 1em;
}

.alert {
    padding: 5px;
    border-radius: 5px;
}

.alert-success {
    background: $white-2;
    color: $black-5;
    border: 1px solid $black-3;
}

.alert-error {
    background: $red-3;
    color: $white-1;
}

.alert-error h2 {
    color: $white-2;
}

#login-container-desktop .pure-menu-list {
    margin: auto 0 auto auto !important;
}
#footer-menu {
    background-color: $black-3;
}
a.footer-link {
    color: $white-2;
    display: block;
    padding: 5px;
    text-decoration: none;
}
a.footer-link:hover {
    background-color: $white-2;
    color: $black-5;
}
.footer-header {
    font-size: 1.1em;
    border-bottom: 1px solid $white-2;
    padding: 5px;
    color: $white-2;
}
.footer-margins {
    margin: 10px;
}
@media screen and (max-width: 35.499em) {
    .footer-header,
    .footer-link {
        text-align: center;
    }
}

.text-center {
    text-align: center;
}

.page-title {
    background: $black-4;
    width: 100%;
}

.page-title .content-container {
    padding-top: 0px;
    padding-bottom: 0px;
}

.page-title h1 {
    color: $white-2;
    font-weight: 300;
    font-size: 3em;
    margin: 0;
    text-transform: uppercase;
}

.page-title .crumbs {
    padding-bottom: 5px;
}

.page-title .crumbs,
.page-title .crumbs a {
    color: $white-3;
    font-size: 1.1em;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

.page-title .crumbs a:hover {
    color: $white-1;
}

.page-title .crumbs .arrow {
    font-size: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

@media (max-width: $md-break) {
    .page-title h1 {
        font-size: 2em;
    }
}

.modal__overlay {
    z-index: 10000;
}

a.button-control:visited {
    color: #ffffff;
}

.inch-1 {
    font-size: 36px;
}

.bold {
    font-weight: bold;
}

.tabs {
    border-bottom: 1px solid $black-2;
}

.tabs .tab {
    display: inline-block;
    padding: 10px;
    color: $blue-5;
    font-weight: 500;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
}

.tabs .tab.active {
    color: $black-5;
    border-top: 1px solid $black-3;
    border-left: 1px solid $black-3;
    border-right: 1px solid $black-3;
}

.tooltip-button {
    padding: 5px 10px;
    font-size: 0.8em;
    background: $black-4;
    border-radius: 50%;
}

.tip {
    padding: 5px;
    position: absolute;
    z-index: 9999;
    cursor: default;
    animation: 0.5s fadeIn;
}

.tip-content {
    padding: 10px;
    border-radius: 5px;
    background: #000;
    color: #fff;
}

.tip-hider {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10000;
}

.tip:after {
    content: '';
    width: 0;
    height: 0;
    margin: auto;
    display: block;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    border: 5px solid transparent;
    border-bottom: none;
    border-top-color: #000;
}

#pay-frequency-form .submit-button {
    display: none;
}

/*h1 {
    font-size: 2em;
}
h2 {
    font-size: 1.6em;
}
h3 {
    font-size: 1.4em;
}*/

a * {
    pointer-events: none;
}
.modal__container {
    min-width: 300px;
}
.visited\:text-white:visited{
    color: rgb(255, 255, 255 );
}
.hover\:border:hover{
    border-width: 1px;
}
.hover\:border-2:hover{
    border-width: 2px;
}
.hover\:border-solid:hover{
    border-style: solid;
}
.hover\:border-gray-300:hover{
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.hover\:border-logoblue-100:hover{
    --tw-border-opacity: 1;
    border-color: rgba(60, 51, 122, var(--tw-border-opacity));
}
.hover\:border-purple-600:hover{
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}
.hover\:border-red-500:hover{
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.hover\:\!bg-gray-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
}
.hover\:\!bg-indigo-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
}
.hover\:bg-blue-200:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.hover\:bg-blue-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.hover\:bg-gray-100:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.hover\:bg-gray-50:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.hover\:bg-green-500:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.hover\:bg-green-700:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.hover\:bg-green-800:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.hover\:bg-transparent:hover{
    background-color: transparent;
}
.hover\:bg-white:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.hover\:\!text-logoblue-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(60, 51, 122, var(--tw-text-opacity)) !important;
}
.hover\:\!text-white:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}
.hover\:text-gray-200:hover{
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.hover\:text-gray-500:hover{
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.hover\:text-logoblue-100:hover{
    --tw-text-opacity: 1;
    color: rgba(60, 51, 122, var(--tw-text-opacity));
}
.hover\:text-purple-600:hover{
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
}
.hover\:text-red-500:hover{
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.hover\:text-white:hover{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.focus\:z-10:focus{
    z-index: 10;
}
.focus\:outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus\:ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 rgba(0,0,0,0);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0,0,0,0));
}
.focus\:ring-indigo-500:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}
.focus\:ring-offset-2:focus{
    --tw-ring-offset-width: 2px;
}
.group:hover .group-hover\:text-gray-600{
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
}
@media (min-width: 640px){
    .sm\:ml-3{
        margin-left: 0.75rem;
    }
    .sm\:mr-6{
        margin-right: 1.5rem;
    }
    .sm\:mt-0{
        margin-top: 0px;
    }
    .sm\:mt-1{
        margin-top: 0.25rem;
    }
    .sm\:\!block{
        display: block !important;
    }
    .sm\:hidden{
        display: none;
    }
    .sm\:max-w-3xl{
        max-width: 48rem;
    }
    .sm\:grid-cols-2{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .sm\:flex-row{
        flex-direction: row;
    }
    .sm\:flex-wrap{
        flex-wrap: wrap;
    }
    .sm\:px-6{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .sm\:text-sm{
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
}
@media (min-width: 768px){
    .md\:ml-0{
        margin-left: 0px;
    }
    .md\:\!block{
        display: block !important;
    }
    .md\:flex{
        display: flex;
    }
    .md\:hidden{
        display: none;
    }
    .md\:max-w-xs{
        max-width: 20rem;
    }
}
@media (min-width: 1024px){
    .lg\:max-w-3xl{
        max-width: 48rem;
    }
    .lg\:max-w-7xl{
        max-width: 80rem;
    }
    .lg\:grid-cols-4{
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .lg\:px-8{
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
