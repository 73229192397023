@font-face {
  font-family: 'icomoon';
  src:  url('/public/fonts/icomoon.eot?ac80z7');
  src:  url('/public/fonts/icomoon.eot?ac80z7#iefix') format('embedded-opentype'),
    url('/public/fonts/icomoon.ttf?ac80z7') format('truetype'),
    url('/public/fonts/icomoon.woff?ac80z7') format('woff'),
    url('/public/fonts/icomoon.svg?ac80z7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user:before {
  content: "\f007";
}
.icon-check-square-o:before {
  content: "\f046";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-check-circle:before {
  content: "\f058";
}
.icon-ban:before {
  content: "\f05e";
}
.icon-chevron-up:before {
  content: "\f077";
}
.icon-chevron-down:before {
  content: "\f078";
}
.icon-caret-down:before {
  content: "\f0d7";
}
.icon-caret-up:before {
  content: "\f0d8";
}
.icon-caret-left:before {
  content: "\f0d9";
}
.icon-caret-right:before {
  content: "\f0da";
}
.icon-building-o:before {
  content: "\f0f7";
}
.icon-bank:before {
  content: "\f19c";
}
.icon-institution:before {
  content: "\f19c";
}
.icon-university:before {
  content: "\f19c";
}
.icon-black-tie:before {
  content: "\f27e";
}
