@import 'css/vars.scss';

/**
 * Selfmade additions on pure-css for things that were honesly kind of missing
 */

.pure-form label {
    font-weight: bold;
}

// Make the inputs match the height of select boxes
// TODO make this rule less... uhm. Dumb? Lol
.pure-form input:not([type="submit"]):not([type="button"]):not([type="radio"]):not([type="checkbox"]) {
    height: 2.25em;
}

.pure-gutter {
    padding-left: 5px;
    padding-right: 5px;
}

.pure-container {
    margin: 0 auto;
    max-width: 1200px;
}

.pure-middle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pure-rounded-img {
    border-radius: 50%;
    border: 3px solid $black-1;
}

.pure-no-greed {
    whitespace: no-wrap;
}

.pure-greedy-column {
    width: 100%;
}

.pure-form input:focus:invalid,
.pure-form select:focus:invalid,
.pure-form textarea:focus:invalid
{
    color: initial;
}

.inline {
    display: inline-block;
}

.pure-u-xs-hidden,
.pure-u-sm-hidden,
.pure-u-md-hidden,
.pure-u-lg-hidden,
.pure-u-xl-hidden {
    visibility: hidden;
    display: none;
}

.pure-u-xs-visible,
.pure-u-sm-visible,
.pure-u-md-visible,
.pure-u-lg-visible {
    visibility: hidden;
    display: none;
}

// SM
@media screen and (min-width: $sm-break) {
    .pure-u-xs-hidden {
        visibility: visible;
        display: initial;
    }
}

@media screen and (max-width: $sm-break) {
    .pure-u-xs-visible {
        visibility: visible;
        display: initial;
    }
}

// MD
@media screen and (min-width: $md-break) {
    .pure-u-sm-hidden {
        visibility: visible;
        display: initial;
    }
    .pure-md-center {
        text-align: center;
    }
}

@media screen and (max-width: $md-break) {
    .pure-u-sm-visible {
        visibility: visible;
        display: initial;
    }
}

// LG
@media screen and (min-width: $lg-break) {
    .pure-u-md-hidden {
        visibility: visible;
        display: initial;
    }
}

@media screen and (max-width: $lg-break) {
    .pure-u-md-visible {
        visibility: visible;
        display: initial;
    }
}

// XL
@media screen and (min-width: $xl-break) {
    .pure-u-lg-hidden {
        visibility: visible;
        display: initial;
    }
}

@media screen and (max-width: $xl-break) {
    .pure-u-lg-visible {
        visibility: visible;
        display: initial;
    }
}
